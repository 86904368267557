import { googleLogout } from '@react-oauth/google'
import { ReactNode, createContext, useState } from 'react'
import { removeLocalUser, storeLocalUser } from '../utils/authenticationUtils'

interface IUser {
    accessToken: string,
    name: string,
    email: string,
    photo: string
}

interface IAuthenticationContextProps {
    user: IUser | null,
    setUser: (newUser: IUser | null) => void
    login: (googleResponse: any, userProfile: any) => void
    logout: () => void
}

const AuthContext = createContext<IAuthenticationContextProps | undefined>(undefined)

interface AuthProviderProps {
    children: ReactNode
}

const AuthContextProvider = ({ children }: AuthProviderProps) => {
    const [user, setUser] = useState<IUser | null>(null)

    const login = (googleResponse: any, userProfile: any) => {
        if (!googleResponse) return
        if (userProfile.verified_email && googleResponse.access_token) {
            setUser({
                accessToken: googleResponse.access_token,
                email: userProfile.email,
                name: userProfile.name,
                photo: userProfile.picture
            })
            // ExpireIn : one day
            storeLocalUser(googleResponse.access_token, 1, userProfile.name)
        }
    }

    const logout = () => {
        googleLogout()
        removeLocalUser()
        setUser(null)
    }

    return (
        <AuthContext.Provider value={{ user, login, logout, setUser }}>
            {children}
        </AuthContext.Provider>
    )
}

export { AuthContext, AuthContextProvider }