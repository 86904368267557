import { Outlet } from 'react-router-dom'
import Navbar from '../navigation/Navbar'
import { Toaster } from 'sonner'
import { useEffect } from 'react'

const Wrapper = () => {
    return (
        <>
            <Navbar/>
            <Outlet />
            <Toaster richColors closeButton />
        </>
    )
}

export default Wrapper