import { Stack, Typography, styled } from '@mui/material'
import loginBgImage from '../../assets/images/login_background.webp'
import { useTranslation } from 'react-i18next'
import { theme } from '../../config/theme'

const Container = styled(Stack)({
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    padding: theme.spacing(10),
    alignItems: 'center',
    flexWrap: 'wrap'
})

const Section = styled(Stack)({
    width: '50%',
    justifyContent: 'center'
})

const TitleContainer = styled(Stack)(({ theme }) => ({
    flexDirection: 'row',
    alignItems: 'flex-end',
    gap: theme.spacing(1.5)
}))

const About = () => {
    const { t } = useTranslation()
    return (
        <Container>
            <Section>
                <TitleContainer>
                    <Typography variant="h4">
                        {t('views.about.title')}
                    </Typography>
                    <Stack flexDirection="row">
                        <Typography variant="h3" component="div">
                            {t('app.name.first')}
                        </Typography>
                        <Typography variant="h3" component="div" color="secondary">
                            {t('app.name.second')}
                        </Typography>
                    </Stack>
                </TitleContainer>
                <Typography variant="body2">{t('views.about.description')}</Typography>
            </Section>
            <Section>
                <img
                    src={loginBgImage}
                    alt="Visuel de SenAI"
                />
            </Section>
        </Container>
    )
}

export default About