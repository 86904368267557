import { CircularProgress, Stack, Typography, styled } from '@mui/material'
import { theme } from '../../config/theme'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { getGlobalData } from './service/MonitoringService'

interface IGlobalData {
    averageCost: number | undefined,
    averageTokens: number | undefined,
    totalRequests: number | undefined
}

const Container = styled(Stack)({
    flex: 1,
    padding: theme.spacing(10),
    gap: theme.spacing(5)
})

const ContentContainer = styled(Stack)({
    gap: theme.spacing(1.5)
})

const Row = styled(Stack)({
    flexDirection: 'row'
})

const Monitoring = () => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [globalData, setGlobalData] = useState<IGlobalData>()

    useEffect(() => {
        setIsLoading(true)
        getGlobalData()
            .then((data: any) => {
                setIsLoading(false)
                setGlobalData(data)
            })
            .catch(() => console.error('Error fetching global data'))
    }, [])

    return (
        <Container>
            <Typography sx={{ textAlign: 'center' }} variant="h3">{t('views.monitoring.title')}</Typography>
            {isLoading ? (
                <Stack sx={{ alignItems: 'center' }}>
                    <CircularProgress />
                </Stack>
            ) : (
                <ContentContainer>
                    <Row>
                        <Typography variant="h5">{t('views.monitoring.globalData.averageTokens', { averageTokens: globalData?.averageTokens ? globalData?.averageTokens : t('global.blankState.na') })}</Typography>
                    </Row>
                    <Row>
                        <Typography variant="h5">{t('views.monitoring.globalData.averageCost', { averageCost: globalData?.averageCost ? globalData?.averageCost : t('global.blankState.na') })}</Typography>
                    </Row>
                    <Row>
                        <Typography variant="h5">{t('views.monitoring.globalData.totalRequests', { totalRequests: globalData?.totalRequests ? globalData?.totalRequests : t('global.blankState.na') })}</Typography>
                    </Row>
                </ContentContainer>
            )}
        </Container>
    )
}

export default Monitoring