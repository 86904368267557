import { CircularProgress, IconButton, InputAdornment, TextField, styled } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import { useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { sendRequest } from '../../../modules/exchange/service/ExchangeService'

interface IExchangeInputProps {
    state: any
    dispatch: any
}

interface IExchangeInputForm {
    request: string
}

const RequestInput = styled(TextField)({
    width: '80%'
})

const ExchangeInput = ({ state, dispatch }: IExchangeInputProps) => {
    const { t } = useTranslation()
    const { handleSubmit, control, reset } = useForm<IExchangeInputForm>()

    const [isLoading, setIsLoading] = useState(false)

    const onSubmit: SubmitHandler<IExchangeInputForm> = (data) => {
        setIsLoading(true)
        const newMessage: Message = {
            id: state.messages.length + 1,
            sender: 'user',
            text: data.request
        }
        dispatch({ type: 'ADD_MESSAGE', payload: newMessage })
        sendRequest(data)
            .then(response => {
                const responseMessage: Message = {
                    id: state.messages.length + 1,
                    sender: 'ai',
                    text: response
                }
                dispatch({ type: 'ADD_MESSAGE', payload: responseMessage })
                reset({
                    request: ''
                })
            })
            .catch(err => console.error('error during send request', err))
            .finally(() => setIsLoading(false))
    }

    const handleEnterKey = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
                name="request"
                control={control}
                render={({ field }) => (
                    <RequestInput
                        placeholder={t('form.input.exchange.placeholder')}
                        onKeyDown={handleEnterKey}
                        multiline
                        {...field}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {isLoading ? (
                                        <CircularProgress />
                                    ) : (
                                        <IconButton type="submit" edge="end">
                                            <SendIcon />
                                        </IconButton>
                                    )}
                                </InputAdornment>
                            )
                        }}
                    />
                )}
            />
        </form>
    )
}

export default ExchangeInput