import { AppBar, Box, Stack, Toolbar, Typography, Container, IconButton, Avatar, Menu, MenuItem, Divider, styled, Button } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import InfoIcon from '@mui/icons-material/Info'
import BarChartIcon from '@mui/icons-material/BarChart'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useAuthentication from '../../hooks/UseAuthentication'
import { Link, useNavigate } from 'react-router-dom'
import logoSensAI from '../../assets/images/ipsoai_logo.png'

const NavContainer = styled(Box)({
    flexGrow: 1,
    position: 'fixed',
    top: 0,
    width: '100%'
})

const Logo = styled('img')({
    width: '50px'
})

const ProfilMenuItem = styled(MenuItem)({
    gap: '5px'
})

const Navbar = () => {
    const { t } = useTranslation()
    const { user, logout } = useAuthentication()
    const navigate = useNavigate()

    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)

    const handleOpenProfileMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseProfileMenu = () => {
        setAnchorElUser(null)
    }

    return (
        <NavContainer>
            <AppBar position="static">
                <Container maxWidth="xl">
                    <Toolbar>
                        <Stack sx={{ flexGrow: 1, flexDirection: 'row', alignItems: 'center' }}>
                            <Button
                                style={{ color: 'white', textTransform: 'none' }}
                                onClick={() => navigate('/')}
                            >
                                <Logo
                                    src={logoSensAI}
                                    alt="Logo de SensAI"
                                />
                                <Typography variant="h6" component="div">
                                    {t('app.name.first')}
                                </Typography>
                                <Typography variant="h6" component="div" sx={{ marginRight: 5 }} color="secondary">
                                    {t('app.name.second')}
                                </Typography>
                            </Button>
                            <Button
                                onClick={() => navigate('/')}
                                color="inherit"
                            >
                                {t('nav.appbar.chat')}
                            </Button>
                        </Stack>
                        <Box sx={{ flexGrow: 0 }}>
                            <IconButton onClick={handleOpenProfileMenu} sx={{ p: 0 }}>
                                <Avatar alt={user?.name} src={user?.photo} />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseProfileMenu}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                {/* <ProfilMenuItem onClick={() => navigate('/account')}>
                                    <AccountCircleIcon />
                                    <Typography textAlign="center">{t('nav.appbar.profileMenu.account')}</Typography>
                                </ProfilMenuItem> */}
                                <ProfilMenuItem onClick={() => {
                                    navigate('/about')
                                    handleCloseProfileMenu()
                                }}>
                                    <InfoIcon />
                                    <Typography textAlign="center">{t('nav.appbar.profileMenu.about')}</Typography>
                                </ProfilMenuItem>
                                <ProfilMenuItem onClick={() => {
                                    navigate('/monitoring')
                                    handleCloseProfileMenu()
                                }}>
                                    <BarChartIcon />
                                    <Typography textAlign="center">{t('nav.appbar.profileMenu.monitoring')}</Typography>
                                </ProfilMenuItem>
                                {/* <ProfilMenuItem onClick={() => navigate('/settings')}>
                                    <SettingsIcon />
                                    <Typography textAlign="center">{t('nav.appbar.profileMenu.settings')}</Typography>
                                </ProfilMenuItem> */}
                                <Divider />
                                <ProfilMenuItem onClick={() => logout()}>
                                    <LogoutIcon />
                                    <Typography textAlign="center">{t('nav.appbar.profileMenu.logout')}</Typography>
                                </ProfilMenuItem>
                            </Menu>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </NavContainer>
    )
}

export default Navbar