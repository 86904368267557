import { addDays, getTime } from 'date-fns'
import { LOCAL_STORAGE_ITEM } from './constants'

export const storeLocalUser = (accessToken: string, expiresIn: number, username: string) => {
    const currentDate = new Date()
    localStorage.setItem(LOCAL_STORAGE_ITEM.ACCESS_TOKEN, accessToken)
    localStorage.setItem(LOCAL_STORAGE_ITEM.TOKEN_EXPIRES_IN, expiresIn.toString())
    localStorage.setItem(LOCAL_STORAGE_ITEM.TOKEN_EXPIRES_TIMESTAMP, (getTime(addDays(currentDate, expiresIn))).toString())
    localStorage.setItem(LOCAL_STORAGE_ITEM.USERNAME, username)
}

export const removeLocalUser = () => {
    localStorage.removeItem(LOCAL_STORAGE_ITEM.ACCESS_TOKEN)
    localStorage.removeItem(LOCAL_STORAGE_ITEM.TOKEN_EXPIRES_IN)
    localStorage.removeItem(LOCAL_STORAGE_ITEM.TOKEN_EXPIRES_TIMESTAMP)
    localStorage.removeItem(LOCAL_STORAGE_ITEM.USERNAME)
}

export const loadLocalUser = () => {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_ITEM.ACCESS_TOKEN)
    const expiresIn = localStorage.getItem(LOCAL_STORAGE_ITEM.TOKEN_EXPIRES_IN)
    const expiresTimestamp = localStorage.getItem(LOCAL_STORAGE_ITEM.TOKEN_EXPIRES_TIMESTAMP)
    const username = localStorage.getItem(LOCAL_STORAGE_ITEM.USERNAME)

    if (!accessToken || !expiresIn) {
        removeLocalUser()
        return undefined
    }
    return {
        accessToken,
        expiresIn: Number(expiresIn),
        expiresTimestamp,
        username
    }
}

const getExpirationTimestamp = () => Number(localStorage.getItem(LOCAL_STORAGE_ITEM.TOKEN_EXPIRES_TIMESTAMP) ? localStorage.getItem(LOCAL_STORAGE_ITEM.TOKEN_EXPIRES_TIMESTAMP) : 0)

export const getTokenTTL = () => {
	const expiration = getExpirationTimestamp()
	return expiration - Date.now() - 60 * 1000
}

export const isTokenExpired = () => getTokenTTL() <= 0