import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Stack, Tooltip, styled } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import { useState } from 'react'
import useExchange from '../../hooks/UseExchange'
import { useTranslation } from 'react-i18next'
import DragAndDrop from '../../components/form/files/DragAndDrop'
import ExchangeInput from '../../components/form/input/ExchangeInput'
import Message from '../../components/chat/Message'
import { uploadFiles } from './service/DocumentExchangeService'
import { toast } from 'sonner'
import useAuthentication from '../../hooks/UseAuthentication'
import { cleanMyHistory } from './service/ExchangeService'

const Container = styled(Stack)(({ theme }) => ({
    marginTop: theme.spacing(5),
    marginLeft: '30%',
    marginRight: '30%'
}))

const MessageContainer = styled(Stack)({
    overflow: 'auto',
    marginTop: '50px',
    marginBottom: '60px'
})

const BottomContainer = styled(Stack)(({ theme }) => ({
    position: 'fixed',
    width: '50%',
    bottom: 0,
    padding: theme.spacing(1),
    backgroundColor: 'white',
    zIndex: 100
}))

const ImportFilesFab = styled(Fab)(({ theme }) => ({
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    '&:hover': {
        backgroundColor: theme.palette.primary.light
    }
}))

const ResetFab = styled(Fab)(({ theme }) => ({
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(10),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    '&:hover': {
        backgroundColor: theme.palette.secondary.dark
    }
}))

const Exchange = () => {
    const { t } = useTranslation()
    const { state, dispatch } = useExchange()
    const { user } = useAuthentication()

    const [dialogAddFilesOpen, setDialogAddFilesOpen] = useState(false)
    const [cleanHistoryDialogOpen, setCleanHistoryDialogOpen] = useState(false)
    const [files, setFiles] = useState<FileList | undefined>(undefined)
    const [lockUploadFile, setLockUploadFile] = useState<boolean>(false)

    const handleUploadFiles = () => {
        if (files && files.length > 0) {
            const data = new FormData()
            Array.from(files).forEach(file => {
                data.append('files', file)
            })
            setLockUploadFile(true)
            uploadFiles(data)
                .then(() => {
                    setDialogAddFilesOpen(false)
                    setFiles(undefined)
                    toast.success(t('toast.uploadFiles.success'))
                })
                .catch(err => {
                    toast.error(t('toast.uploadFiles.error'))
                    console.error(err)
                })
                .finally(() => setLockUploadFile(false))
        }
    }

    return (
        <Stack>
            <Container>
                <MessageContainer>
                    {state.messages.map((message: Message) => (
                        <Message
                            key={`${message.sender}-${message.id}`}
                            message={message.text}
                            name={(user && message.sender !== 'ai') ? user.name : `${t('app.name.first')}${t('app.name.second')}`}
                            isOwnMessage={message.sender !== 'ai'}
                        />
                    ))}
                </MessageContainer>
                <BottomContainer>
                    <ExchangeInput state={state} dispatch={dispatch} />
                    <Tooltip title={t('views.exchange.tooltip.importDocs')}>
                        <ImportFilesFab onClick={() => setDialogAddFilesOpen(true)}>
                            <AddIcon />
                        </ImportFilesFab>
                    </Tooltip>
                    <Tooltip title={t('views.exchange.tooltip.cleanHistory')}>
                        <ResetFab onClick={() => setCleanHistoryDialogOpen(true)}>
                            <RestartAltIcon />
                        </ResetFab>
                    </Tooltip>
                </BottomContainer>
            </Container>
            <Dialog
                open={dialogAddFilesOpen}
                onClose={() => setDialogAddFilesOpen(false)}
            >
                <DialogTitle>{t('views.exchange.dialog.files.title')}</DialogTitle>
                <DialogContent>
                    <DragAndDrop files={files} setFiles={setFiles} isLoading={lockUploadFile} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogAddFilesOpen(false)}>{t('global.actions.cancel')}</Button>
                    <Button onClick={handleUploadFiles} disabled={lockUploadFile}>{t('global.actions.add')}</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={cleanHistoryDialogOpen}
                onClose={() => setCleanHistoryDialogOpen(false)}
            >
                <DialogTitle>{t('views.exchange.dialog.cleanHistory.title')}</DialogTitle>
                <DialogContent>
                    <p>{t('views.exchange.dialog.cleanHistory.text')}</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setCleanHistoryDialogOpen(false)}>{t('global.actions.cancel')}</Button>
                    <Button onClick={() => {
                        cleanMyHistory()
                            .then(() => {
                                dispatch({ type: 'CLEAN_HISTORY' })
                                toast.success(t('toast.cleanHistory.success'))
                            })
                            .catch(() => toast.error(t('toast.cleanHistory.error')))
                            .finally(() => setCleanHistoryDialogOpen(false))
                    }}>{t('global.actions.clean')}</Button>
                </DialogActions>
            </Dialog>
        </Stack>
    )
}

export default Exchange