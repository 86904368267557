import React from 'react'
import { createContext } from 'react'

type ExchangeStateType = {
    messages: Message[]
}

type ExchangeActionType =
    | { type: 'ADD_MESSAGE', payload: Message }
    | { type: 'LOAD_HISTORY', payload: Message[]}
    | { type: 'CLEAN_HISTORY' }

const exchangeReducer = (state: ExchangeStateType, action: ExchangeActionType) => {
    switch (action.type) {
        case 'ADD_MESSAGE':
            return { ...state, messages: [...state.messages, action.payload] }
        case 'LOAD_HISTORY':
            if (state.messages.length === 0) {
                return {
                    ...state, messages: [...action.payload]
                }
            }
            return state
        case 'CLEAN_HISTORY':
            return { ...state, messages: [] }
        default:
            return state
    }
}

type ExchangeContextProviderProps = {
    children: React.ReactNode
}

const ExchangeContext = createContext<ExchangeContextType | undefined>(undefined)

const ExchangeContextProvider = ({ children }: ExchangeContextProviderProps) => {
    const [state, dispatch] = React.useReducer(exchangeReducer, { messages: [] })

    return (
        <ExchangeContext.Provider value={{ state, dispatch }}>
            {children}
        </ExchangeContext.Provider>
    )
}

export { ExchangeContext, ExchangeContextProvider }