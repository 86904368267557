import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import fr from '../assets/locales/fr.json'

const resources = {
    fr: {
        translation: fr
    }
}

i18next
.use(LanguageDetector)
.use(initReactI18next)
.init({
    resources,
    fallbackLng: 'fr',
    interpolation: {
        escapeValue: false // react already safes from xss
    }
})

export default i18next