import { ThemeProvider } from '@mui/material'
import { Route, Routes } from 'react-router-dom'
import { theme } from './config/theme'
import Login from './modules/auth/Login'
import Exchange from './modules/exchange/Exchange'
import { ExchangeContextProvider } from './hooks/ExchangeContext'
import Wrapper from './components/layout/Wrapper'
import PrivateRoute from './hooks/PrivateRoute'
import About from './modules/about/About'
import Monitoring from './modules/monitoring/Monitoring'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ExchangeContextProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<PrivateRoute Component={Wrapper} />}>
            <Route path="/" element={<Exchange />} />
            <Route path="/about" element={<About />} />
            <Route path='/monitoring' element={<Monitoring />} />
          </Route>
        </Routes>
      </ExchangeContextProvider>
    </ThemeProvider>
  )
}

export default App
