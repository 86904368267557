import { useContext } from 'react'
import { ExchangeContext } from './ExchangeContext'

const useExchange = () => {
    const context = useContext(ExchangeContext)
    if (!context) {
        throw new Error('useExchange must be used within a ExchangeContextProvider')
    }
    return context
}

export default useExchange