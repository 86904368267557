import { useContext } from 'react'
import { AuthContext } from './AuthenticationContext'

const useAuthentication = () => {
    const context = useContext(AuthContext)
    if (!context) {
        throw new Error('useAuth doit être utilisé à l\'intérieur d\'un AuthProvider')
    }
    return context
}

export default useAuthentication