import { Stack, Typography, styled } from "@mui/material"

type MessageProps = {
    message: string,
    name: string,
    isOwnMessage: boolean
}

const MessageContainer = styled(Stack, {
    shouldForwardProp: (prop: string) => prop !== 'isOwnMessage'
})<{ isOwnMessage: boolean }>(
    ({ isOwnMessage, theme }) => ({
        width: 'fit-content',
        padding: theme.spacing(1),
        borderRadius: theme.spacing(1),
        color: isOwnMessage ? theme.palette.secondary.contrastText: theme.palette.primary.contrastText,
        alignSelf: 'flex-start',
        backgroundColor: isOwnMessage ? theme.palette.secondary.main : theme.palette.primary.main
    })
)

const Message = ({ message, name, isOwnMessage }: MessageProps) => {
    return (
        <Stack margin={1} sx={{ alignSelf: isOwnMessage ? 'flex-end' : 'flex-start' }}>
            <MessageContainer isOwnMessage={isOwnMessage}>
                <Typography variant="body2">{message}</Typography>
            </MessageContainer>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>{name}</Typography>
        </Stack>
    )
}

export default Message