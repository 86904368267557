import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette: {
        primary: {
            main: '#252012'
        },
        secondary: {
            main: '#CBD300'
        }
    }
})