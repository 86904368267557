import { LOCAL_STORAGE_ITEM } from "./constants"

const handleResponse = (response: Response) => {
    if (response.status === 204) {
        return Promise.resolve()
    }
    return Promise.all([response, response.json()])
    .then(checkErrors)
}

const checkErrors = ([response, json]: [Response, Promise<any>]) => {
    if (response.status === 400) {
        return Promise.reject({
            response,
            error: json
        })
    }
    return json
}

const fetchSimpleFactory = (path: string, config: any = {}) => {
    const {
        headers,
        ...others
    } = config

    let defaultHeaders = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-Username': ''
    }

    if (localStorage.getItem(LOCAL_STORAGE_ITEM.USERNAME)) {
        defaultHeaders['X-Username'] = localStorage.getItem(LOCAL_STORAGE_ITEM.USERNAME) as string
    }

    const newConfig = {
        headers: {
            ...defaultHeaders,
            ...headers
        },
        ...others
    }
    return fetch(`${path}`,newConfig)
}

export const fetchFactory = (path: string, config = {}) => {
    return fetchSimpleFactory(path, config)
        .then(handleResponse)
}

export const fetchFileFactory = (path: string, config: any = {}) => {
    const {
        headers,
        ...others
    } = config

    const newConfig = {
        headers: {
            ...headers
        },
        ...others
    }

    return fetch(`${path}`,newConfig)
        .then(handleResponse)
}