import { fetchFactory } from '../../../utils/fetchFactory'

export const sendRequest = async (request: object) => fetchFactory('/api/exchange/', {
    method: 'POST',
    body: JSON.stringify(request)
})

export const getMyHistory = async () => fetchFactory('/api/exchange/history', {
    method: 'GET'
})

export const cleanMyHistory = async () => fetchFactory('/api/exchange/clean-history', {
    method: 'GET'
})