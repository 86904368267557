import { Button, Stack, Typography, styled } from '@mui/material'
import GoogleIcon from '@mui/icons-material/Google'
import { useTranslation } from 'react-i18next'
import { useGoogleLogin } from '@react-oauth/google'
import loginBgImage from '../../assets/images/login_background.webp'
import useAuthentication from '../../hooks/UseAuthentication'
import { useNavigate } from 'react-router-dom'
import { loginUser } from './service/AuthenticationService'
import { VersionNumber } from '../../utils/constants'

const Container = styled(Stack)(({ theme }) => ({
    width: '100%',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${loginBgImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
}))

const Form = styled('form')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '400px',
    padding: theme.spacing(2),
    borderRadius: '5px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    justifyContent: 'center',
    alignItems: 'center',
}))

const LoginButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
        backgroundColor: theme.palette.primary.light
    }
}))

const AppInfoContainer = styled(Stack)(({ theme }) => ({
    width: '100%',
    marginTop: theme.spacing(2),
    flexDirection: 'row',
    justifyContent: 'flex-end',
    color: theme.palette.primary.contrastText
}))

const Login = () => {
    const { t } = useTranslation()
    const { login } = useAuthentication()
    const navigate = useNavigate()

    const handleGoogleLogin = useGoogleLogin({
        hosted_domain: "ipsosenso.com",
        onSuccess: googleResponse => {
            loginUser(googleResponse)
                .then(response => {
                    login(googleResponse, response)
                    navigate('/')
                })
                .catch(error => console.log(error))
        },
        onError: error => console.error(error),
        flow: 'implicit',
        scope: "email profile https://www.googleapis.com/auth/drive openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email"
    })

    return (
        <Container>
            <Form>
                <Typography variant="h4" align="center">{t('views.login.title')}</Typography>
                <LoginButton
                    onClick={() => handleGoogleLogin()}
                    startIcon={<GoogleIcon color="secondary" />}
                >
                    {t('views.login.btn')}
                </LoginButton>
                <AppInfoContainer>
                    <Typography variant="caption" color="primary">
                        {t('app.version', { versionNumber: VersionNumber})}
                    </Typography>
                </AppInfoContainer>
            </Form>
        </Container>
    )
}

export default Login