import { CircularProgress, Link, List, ListItem, ListItemText, Stack, Typography, styled } from '@mui/material'
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined'
import { useRef, useState } from 'react'
import { FILES_FORMAT_ALLOWED } from '../../../utils/constants'

interface IDragAndDropProps {
    files: FileList | undefined,
    setFiles: (files: FileList | undefined) => void,
    isLoading: boolean
}

const Container = styled(Stack)(({ theme }) => ({
    flex: 1,
    padding: theme.spacing(2)
}))

const DragZone = styled(Stack)(({ theme }) => ({
    flex: 1,
    alignItems: 'center',
	justifyContent: 'center',
    border: `2px dashed ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    padding: theme.spacing(3),
    transition: 'border .24s ease-in-out',
    '&:hover': {
        border: `2px dashed ${theme.palette.secondary.main}`
    }
}))

const DragInvisibleZone = styled(Stack)({
	position: 'absolute',
	width: '100%',
	height: '100%',
	borderRadius: '1rem',
	top: 0,
	left: 0,
	bottom: 0,
	right: 0
})

const DragAndDrop = ({ files, setFiles, isLoading }: IDragAndDropProps) => {
    const inputFilesRef = useRef<HTMLInputElement>(null)
    const [dragActive, setDragActive] = useState<boolean>(false)

    const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
		e.stopPropagation()
		if (e.type === 'dragenter' || e.type === 'dragover') {
			setDragActive(true)
		} else if (e.type === 'dragleave') {
			setDragActive(false)
		}
    }

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault()
		e.stopPropagation()
		setDragActive(false)
		if (e.dataTransfer.files && e.dataTransfer.files[0]) {
			setFiles(e.dataTransfer.files)
		}
	}

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
		if (e.target.files && e.target.files[0]) {
			setFiles(e.target.files)
		}
    }

    return (
        <Container onDragEnter={handleDrag}>
            <input ref={inputFilesRef} type="file" accept={FILES_FORMAT_ALLOWED.join(',')} multiple hidden onChange={handleChange} />
            <DragZone>
                <DownloadForOfflineOutlinedIcon color="primary" fontSize="large" />
				<Typography variant="h6">Drag and drop</Typography>
                <Link onClick={() => inputFilesRef.current && inputFilesRef.current.click()}>Ouvrir</Link>
            </DragZone>
            {dragActive && <DragInvisibleZone onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop} />}
            {files && files.length > 0 && (
                <List>
                    {Array.from(files).map((file, index) => (
                        <ListItem key={index}>
                            <ListItemText primary={file.name} />
                        </ListItem>
                    ))}
                </List>
            )}
            {isLoading ? <CircularProgress /> : <></>}
        </Container>
    )
}

export default DragAndDrop